Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "PremiumPlanComparison";
exports.labelBodyText = "PremiumPlanComparison Body";

exports.btnExampleTitle = "CLICK ME";

exports.ContentType = "application/json";
exports.GETMethod = "GET";
exports.POSTMethod = "POST";
exports.PUTMethod = "PUT";
exports.PATCHMethod = "PATCH";
exports.DELMethod = "DELETE";

exports.getApiEndPoint = "bx_block_premium_plan_comparison/subscription_plans";
exports.getActiveSubscriptionAPI = "bx_block_location/saved_locations/check_subscription";

exports.creatSubscriptionEndPoint = `create_subscribe`;

exports.title = "COMPARE PLANS";
exports.buttonText = "SELECT PLAN";
exports.alertText = "Plan Activated Successfully";
exports.alertMessage = ""
exports.alertButtonText = "OK"

// Customizable Area End
