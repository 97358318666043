Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.loginAPiEndPoint = "bx_block_login/logins";
exports.loginAPiMethod = "POST";
exports.loginApiContentType = "application/json"; 
exports.getMehtod = "GET";
exports.showProfileEndPoint = "show_profile"

// Customizable Area Start
exports.errorEmailNotValid = "Email not valid.";
exports.errorPhoneNoNotValid = "Phone No not valid.";
exports.errorPasswordNotValid = "Password not valid.";
exports.placeHolderEmail = "Email";
exports.placeHolderPassword = "Password";
exports.labelHeader = "SIGN IN";
exports.btnTxtLogin = "LOG IN";
exports.labelRememberMe = "Remember me";
exports.btnTxtSocialLogin = "LOGIN WITH SOCIAL MEDIA";
exports.labelOr = "OR";
exports.labelTitle = "Log in";
exports.forgotPwdText = "	Forgot password?"
exports.signinotpText = "Sign In with OTP"


exports.imgPasswordInVisiblePath = "assets/ic_password_invisible.png";
exports.imgPasswordVisiblePath = "assets/ic_password_visible.png";
exports.premiumPlanComparisonButtonText = "Premium Plan Comparison"
exports.archiveButtonText = "Archive"
exports.EMAIL_PATTERN = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
// Customizable Area End