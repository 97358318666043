import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible } from "./assets";
import Geolocation from "@react-native-community/geolocation";
import { AsyncStorage, PermissionsAndroid, Platform } from "react-native";
import { setStorageData } from "../../../framework/src/Utilities";
import React from "react";
import { StackActions } from "@react-navigation/native";
import requestCameraAndAudioPermission from "../../../components/src/VideoPermission";
// import { PERMISSIONS, request } from "react-native-permissions";
// import { showMessage } from "react-native-flash-message";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: string;
  email: string;
  enablePasswordField: boolean;
  checkedRememberMe: boolean;
  placeHolderEmail: string;
  placeHolderPassword: string;
  imgPasswordVisible: any;
  imgPasswordInVisible: any;
  labelHeader: string;
  btnTxtLogin: string;
  labelRememberMe: string;
  btnTxtSocialLogin: string;
  labelOr: string;
  phone: string;
  isPassword: boolean;
  showLoader: boolean;
  phoneNo: any;
  isMobile: boolean;
  isEmail: boolean;
  emailError: boolean;
  firstError: string;
  phoneError: boolean;
  HidePassword: boolean;
  passError: string;
  latitude: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class EmailAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiEmailLoginCallId: string = "";
  apiSaveProfileId: string = "";
  emailReg: RegExp = new RegExp("");
  labelTitle: string = "";
  textInput: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];
    
    this.state = {
      email: "",
      password: "",
      phone: "",
      enablePasswordField: true,
      checkedRememberMe: false,
      placeHolderEmail: configJSON.placeHolderEmail,
      placeHolderPassword: configJSON.placeHolderPassword,
      imgPasswordVisible: configJSON.imgPasswordVisible,
      imgPasswordInVisible: imgPasswordInVisible,
      labelHeader: configJSON.labelHeader,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelRememberMe: configJSON.labelRememberMe,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      labelOr: configJSON.labelOr,
      isPassword: false,
      showLoader: false,
      phoneNo: null,
      isEmail: false,
      isMobile: false,
      emailError: false,
      firstError: "",
      phoneError: false,
      HidePassword: true,
      passError: "",
      latitude: "",
    };
   
    this.labelTitle = configJSON.labelTitle;
    this.clearUserDetails();
    this.textInput = React.createRef();
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages); 
  }

  async componentDidMount() {
    // Customizable Area Start
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));

    const clearText = this.props.navigation.addListener("focus", () => {
      this.setState({
        email: "",
        password: "",
      });
    });

    this.textInput.current.clear();
    return clearText;

    // Customizable Area End
  }

  // Customizable Area Start
  handleGoToPremiumPlanComparison = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "subscriptionplan");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  handleGoToArchive = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "attachment");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
  
  async callLocation() {
    setTimeout(() => {
      this.handlePremissions();
    }, 100);
  }

  handlePremissions = () => {
    if (Platform.OS === "android") {
      requestCameraAndAudioPermission().then(() => {
        this.fetchLocationRequest();
      });
    } else {
      this.handleIosPremissions();
    }
  };
  handleIosPremissions = () => {
        alert("Permission denied");

  };
  handleIosMicroPhonePremissions = () => {
        alert("Permission denied");

  };

  async fetchLocationRequest() {
    const granted: any = await PermissionsAndroid.request(
      PermissionsAndroid.PERMISSIONS.ACCESS_FINE_LOCATION,
      {
        title: "Cosmoz",
        message: "Cosmoz App access to your location",
        buttonNeutral: "Ask Me Later",
        buttonNegative: "Cancel",
        buttonPositive: "OK",
      }
    );
    if (granted == "denied") {
      alert("Permission denied");
    } else if (granted == "granted") {
      this.initBackgroundFetch();
    }
  }

  async initBackgroundFetch() {
    Geolocation.getCurrentPosition((position) => {
      const { coords } = position;

      const latitude = JSON.stringify(coords?.latitude);

      this.setState({ latitude: latitude });
    });
  }
  async componentWillUnmount() {
    this.focusListener();
  }
  focusListener() {
    throw new Error("Method not implemented.");
  }
  clearUserDetails = async () => {
    try {
      await AsyncStorage.removeItem("userdetails");
    } catch (e) {
      // saving error
    }
  };

  storeAsyncLoginData = async (response: any) => {
    try {
      const jsonValue = JSON.stringify(response);
      await AsyncStorage.setItem("userdetails", jsonValue);
    } catch (e) {
      // saving error
    }
  };

  sendLoginFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
  }

  sendLoginSuccessMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginSuccessMessage));

    msg.addData(getName(MessageEnum.LoginUserName), this.state.email);
    msg.addData(getName(MessageEnum.CountyCodeDataMessage), null);
    msg.addData(getName(MessageEnum.LoginPassword), this.state.password);
    msg.addData(
      getName(MessageEnum.LoginIsRememberMe),
      this.state.checkedRememberMe
    );

    this.send(msg);
  }

  openInfoPage() {
    const msg: Message = new Message(getName(MessageEnum.AccoutLoginSuccess));

    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    this.send(msg);
  }

  goToForgotPassword() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationForgotPasswordMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationForgotPasswordPageInfo), "email");
    this.send(msg);
  }

  goToSocialLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationSocialLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  doEmailLogIn = () => {
    let errorCheck: any = false;

    if (this.state.email === null || this.state.email.length === 0) {
      this.setState({ emailError: true, firstError: "Please enter Email" });
      return false;
    } else if (!configJSON.EMAIL_PATTERN.test(String(this.state.email).toLowerCase())) {

      this.setState({
        emailError: true,
        firstError: configJSON.errorEmailNotValid,
      });
      return false;
    } else if (
      this.state.password === null ||
      this.state.password.length === 0
    ) {
      this.setState({
        isPassword: true,
        passError: "Please enter your password",
      });
      return false;
    } else {
      this.setState({
        isPassword: false,
      });
    }
    this.setState({ showLoader: true });

    if (!errorCheck) {
      this.setState({ showLoader: true });
      const header = {
        "Content-Type": configJSON.loginApiContentType,
      };

      const attrs = {
        email: this.state.email,
        password: this.state.password,
      };

      const data = {
        type: "email_account",
        attributes: attrs,
      };

      const httpBody = {
        data: data,
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.apiEmailLoginCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.loginAPiEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.loginAPiMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);

      return true;
    }
  };

  async saveProfile(token: any) {
    const header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiSaveProfileId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.showProfileEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMehtod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  handleEmailAddressChange = (e: any) => {
    this.setState({
      email: e,
      firstError: "",
      phoneError: false,
      emailError: false,
    });
  };

  handlePasswordChange = (text: string) => {
    this.setState({
      password: text.replace(" ", ""),
      passError: "",
      isPassword: false,
    });
  };

  ForgotPassword = () => {
    this.props.navigation.navigate("ForgotPassword");
  };

  OtpSignIn = () => {
    this.props.navigation.navigate("OtpSignIn");
  };

  EmailAccountRegistration = () =>
    this.props.navigation.navigate("EmailAccountRegistration", {
      isEdit: false,
    });

  hidePassword = () => {
    this.setState({ HidePassword: !this.state.HidePassword });
  };

  epTextInput = (input: any) => {
    this.textInput = input;
  };

  checkEmailLoginResponseId = async (responseJson: any) => {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      await setStorageData("logIn_token", responseJson.meta.refresh_token);
      this.storeAsyncLoginData(responseJson);
      this.saveProfile(responseJson.meta.token);}
    // } else if (responseJson.errors) {
    //   showMessage({
    //     message: responseJson && responseJson.errors[0].failed_login,
    //     type: "danger",
    //     backgroundColor: "#9b282a",
    //     duration: 1000,
    //   });
    // }
  };

  saveProfileIdResponse = async (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.setState({ showLoader: false });
      await setStorageData("userInfo", JSON.stringify(responseJson?.data));
      await setStorageData("name", responseJson?.data?.attributes?.first_name);
      await setStorageData("img", responseJson?.data?.attributes?.image?.url);
      this.props.navigation.dispatch(StackActions.replace("MainNavigator"));
    } else {
      //Check Error Response
      this.parseApiErrorResponse(responseJson);
      this.sendLoginFailMessage();
    }
  };
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.setState({ showLoader: false });
    if (getName(MessageEnum.ReciveUserCredentials) === message.id) {
      const userName = message.getData(getName(MessageEnum.LoginUserName));

      const password = message.getData(getName(MessageEnum.LoginPassword));

      const countryCode = message.getData(
        getName(MessageEnum.LoginCountryCode)
      );

      if (!countryCode && userName && password) {
        this.setState({
          email: userName,
          password: password,
          checkedRememberMe: true,
        });

      }
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId != null) {
        
        if (apiRequestCallId === this.apiEmailLoginCallId) {
          this.checkEmailLoginResponseId(responseJson);

          this.parseApiCatchErrorResponse(errorReponse);
        }

        if (apiRequestCallId === this.apiSaveProfileId) {
          this.saveProfileIdResponse(responseJson);

          this.parseApiCatchErrorResponse(errorReponse);
        }
      }
    }

    // Customizable Area End
  }
}
