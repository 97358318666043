import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
} from "@material-ui/core";


// Customizable Area End

import DashboardForBbController,{Props} from "./DashboardForBbController";


export default class DashboardForBb extends DashboardForBbController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <Container maxWidth="sm">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "10px 0px",
          }}
        >
        
         

          <Box sx={{ width: "100%", paddingTop: "20px" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "10px 0px",
              }}
            >
              
              <Button
                data-test-id={"btnPremium"}
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => this.navigateForBb("PremiumPlan")}
              >
                PremiumPlan Comparison {/*UI Engine::From Sketch*/}
              </Button>
            </Box>
          </Box>
          <Box sx={{ width: "100%", paddingTop: "20px" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "10px 0px",
              }}
            >
              
              <Button
                data-test-id={"btnArchive"}
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => this.navigateForBb("Archive")}
              >
                Archive {/*UI Engine::From Sketch*/}
              </Button>
            </Box>
          </Box>
        </Box>
      </Container>
    );
  }
}
