//@ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import { Alert, Keyboard } from "react-native";
import React from "react";
import { StackActions } from "@react-navigation/native";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  HidePassword: boolean;
  token: any;
  showLoader: boolean;

  cardNo: any;
  monthValue: any;
  yearValue: any;
  cvvValue: any;

  cardNoError: boolean;
  monthValueError: boolean;
  yearValueError: boolean;
  cvvValueError: boolean

  cardNoErrorValue: any,
  monthValueErrorValue: any,
  yearValueErrorValue: any,
  cvvValueErrorValue: any,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class PaymentsController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  postApiId: any;
  cardRef: any;
  monthRef: any;
  yearRef: any;
  nameRef: any;
  cvvRef: any
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.cardRef = React.createRef();
    this.nameRef = React.createRef();
    this.monthRef = React.createRef();
    this.yearRef = React.createRef();
    this.cvvRef = React.createRef();

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      HidePassword: true,
      token: "",
      showLoader: false,

      cardNo: '',
      monthValue: "",
      yearValue: "",
      cvvValue: "",

      cardNoError: false,
      monthValueError: false,
      yearValueError: false,
      cvvValueError: false,

      cardNoErrorValue: '',
      monthValueErrorValue: '',
      yearValueErrorValue: '',
      cvvValueErrorValue: '',
    }

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
    ]

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    let tokens = "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6MzA1OSwiZXhwIjoxNjk4ODA0MDEwfQ.tWqjknWlhPq3qMxYlL-m1Fy0EFJJiZ0QHVsSSYa-ULkRxGnWM3yIAMlJ_KuntqgoKtcTa9fWnEaYgF1xxm_ygg";
    if (tokens) {
      this.setState({ token: tokens, });
    }
    // Customizable Area End
  }


  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    this.setState({ showLoader: false });


    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );


      if (apiRequestCallId === this.postApiId) {
        console.log('RES POST===>', responseJson);
        if (responseJson != null) {
          if (!responseJson.errors) {
            setTimeout(() => {
              Alert.alert(
                "Payment" + " " + responseJson?.transaction?.data?.attributes?.status,
                "",
                [
                  { 
                    text: "OK",
                    onPress: () => this.props.navigation.dispatch(StackActions.replace('MainNavigator')) 
                  }
                ]
              );

            }, 200)
          } else {
            setTimeout(() => {
              Alert.alert(
                "Payment Failed",
                responseJson?.errors,
                [
                  {
                    text: "Go to Dashboard",
                    onPress: () => this.props.navigation.dispatch(StackActions.replace('MainNavigator')),
                    style: "cancel"
                  }
                ]
              );

            }, 200);


          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  makePayment = () => {
    var data = {
      "exp_month": this.state.monthValue,
      "exp_year": this.state.yearValue,
      "number": this.state.cardNo.replace(/ /g,''),
      "cvc": this.state.cvvValue
    }

    this.setState({ showLoader: true })
    const header = {
      'Content-Type': configJSON.ContentType,
      "token": this.state.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postApiId = requestMessage.messageId;

    const httpBody = data;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.makePaymentApiEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.POSTMethod
    );

    console.log('REQUEST: ', requestMessage);

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  goBack = () => {
    this.props.navigation.goBack()
  }

  cardDetail = (text: any) => {
    let length = 0;
    let cardText = "";
    text
      .replace(/[^0-9]/g, "")
      .split("")
      .map((value: any) => {
        if (length == 4) {
          length = 1;
          cardText = cardText + " " + value;
        } else {
          length += 1;
          cardText = cardText + value;
        }
      });

    this.setState({
      cardNo: cardText,
      cardNoError: false,
      cardNoErrorValue: "",
    });
    if (text.length == 19) {
      
      this.monthRef.current.focus();
    }
  }

  monthDetail = (text: any) => {
    this.setState({
      monthValue: text.replace(/[^0-9]/g, ""),
      monthValueErrorValue: "",
      monthValueError: false,
    });
    if (text.length == 2) {
      console.log('Year Ref', this.monthRef);
      this.yearRef.current.focus();
    }
  }

  yearDetail = (text: any) => {
    this.setState({
      yearValue: text.replace(/[^0-9]/g, ""),
      yearValueErrorValue: "",
      yearValueError: false,
    });
    if (text.length == 4) {
      this.cvvRef.current.focus();
    }
  }

  cvvDetail = (text: any) => {
    this.setState({
      cvvValue: text.replace(/[^0-9]/g, ""),
      cvvValueErrorValue: "",
      cvvValueError: false,
    });
    if (text.length == 3) {
      Keyboard.dismiss();
    }
  }

  validate = () => {
    // console.log("validate--->");
    if (this.state.cardNo == null || this.state.cardNo.length === 0) {
      this.setState({
        cardNoError: true,
        cardNoErrorValue: "Please Enter Card No.",
      });
    } else if (this.state.cardNo.length < 16) {
      this.setState({
        cardNoError: true,
        cardNoErrorValue: "Please Enter Valid Card No.",
      });
    } else if (
      this.state.monthValue == null ||
      this.state.monthValue.length === 0
    ) {
      this.setState({
        monthValueError: true,
        monthValueErrorValue: "Please Enter Month",
      });
    } else if (
      this.state.yearValue == null ||
      this.state.yearValue.length === 0
    ) {
      this.setState({
        yearValueError: true,
        yearValueErrorValue: "Please Enter Year",
      });
    } else if (
      this.state.cvvValue == null ||
      this.state.cvvValue.length === 0
    ) {
      this.setState({
        cvvValueError: true,
        cvvValueErrorValue: "Please Enter CVV",
      });
    } else {
      this.makePayment();
    }
  }

  handlePasswordVisibility = () => {
    this.setState({ HidePassword: !this.state.HidePassword })
  }

  // Customizable Area End
}
