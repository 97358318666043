// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import Archive from '../../blocks/Archive/src/Archive.web'
import PremiumPlan from '../../blocks/PremiumPlanComparison/src/PremiumPlanComparison.web';
import Payments from '../../blocks/Payments/src/Payments.web';
import EmailAccountLogin from '../../blocks/email-account-login/src/EmailAccountLoginBlock.web';
import Attachment from '../../blocks/Archive/src/Attachment.web'
import LoginForBB from "../../blocks/email-account-login/src/LoginForBB.web"
import DashboardForBb from "../../blocks/email-account-login/src/DashboardForBb.web"

const routeMap = {
  Home: {
    component: LoginForBB,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  },
  DashboardForBb: {
    component: DashboardForBb,
    path: "/DashboardForBb",
  },
  Archive: {
    component: Archive,
    path: "/archive",
  },
  Attachment: {
    component: Attachment,
    path: "/attachments",
  },
  PremiumPlan: {
    component: PremiumPlan,
    path: "/PremiumPlan"
  },
  Payments: {
    component: Payments,
    path: "/Payments"
  },
  EmailAccountLogin: {
    component: EmailAccountLogin,
    path: "/Login"
  },
  LoginForBB: {
    component: LoginForBB,
    path: "/LoginForBB"
  }
  

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;