import React from "react";
// Customizable Area Start
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Snackbar from '@material-ui/core/Snackbar';
import ArchiveController, {
  Props,configJSON
} from "./ArchiveController";
export default class Archive extends ArchiveController {
  
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <>
        <Typography align="center" style={{ margin: '20px' }} variant="h3" component="h2" gutterBottom>Archive Items</Typography>
        <Button  data-testid= "btnrnav"onClick={this.navigateTo}>{configJSON.goToAttachments}</Button>
        {this.state.loading ? (
          <div
            style={{
              textAlign: 'center',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
            id="loadingTag"
          >
          <CircularProgress />
          </div>
        ) : null}
        {!this.state.loading && this.state.archivedItems.length == 0 ? (
          <p style={{ textAlign: 'center' }}>{configJSON.noItems}</p>
        ) : null}
        {!this.state.loading && this.state.archivedItems.length > 0 ? (
          <List dense={true} style={{ background: 'darkgrey', padding: '0 20px' }}>
            {this.state.archivedItems.map((item, index) => (
              <ListItem key={index} style={{ background: "white", margin: '20px 0px', textTransform: 'capitalize' }}>
                <ListItemText
                  primary={item.attributes.archived_object.name ?? ''}
                  secondary={item.attributes.archived_object.attachment_type}
                />
                <ListItemSecondaryAction>
                  <Button   data-testid="btnrestor" variant="contained" color="primary" 
                  onClick={() => this.restoreArchives(item.id)} size="medium">
                    Restore
                  </Button>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        ) : null}
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          open={this.state.openSnackBar}
          onClose={this.closeSnackBar}
          message={this.state.snackbarMessage}
          key={'bottomright'}
        />
      </>
    );
  }
}

// Customizable Area End


