const { base_url } = require("../../../mobile/Constants");
Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Payments";
exports.labelBodyText = "Payments Body";

exports.btnExampleTitle = "CLICK ME";


exports.ContentType = "application/json";
exports.GETMethod = "GET";
exports.POSTMethod = "POST";
exports.PUTMethod = "PUT";
exports.PATCHMethod = "PATCH";
exports.DELMethod = "DELETE";

exports.getApiEndPoint = "bx_block_subscriptionbilling/subscriptions/";
exports.makePaymentApiEndpoint = "bx_block_subscriptionbilling/subscribers";
exports.creatSubscriptionEndPoint = `${base_url}/create_subscribe`;

// Customizable Area End