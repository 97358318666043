import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Input,
  Typography,
  InputAdornment,
  IconButton,
  Checkbox,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";

// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";


export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <Container maxWidth="sm">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "10px 0px",
          }}
        >
          <Typography variant="h4" component="h2">
            {this.labelTitle}
          </Typography>
          <Typography variant="subtitle1" component="div">
            {this.state.labelHeader} {/*UI Engine::From Sketch*/}
          </Typography>

          <Box sx={{ width: "100%", paddingTop: "20px" }}>
            <Box sx={{ padding: "20px 0px" }}>
              <Input
                data-test-id="txtInputEmail"
                placeholder={"Email"}
                fullWidth={true}
                value={this.state.email}
                onChange={(e) => this.setState({ email: e.target.value})}
              />
              <p>{this.state.firstError}</p>
            </Box>
            <Box sx={{ padding: "20px 0px" }}>
              <Input
                data-test-id="txtInputPassword"
                type={this.state.enablePasswordField ? "password" : "text"}
                placeholder={"Password"}
                fullWidth={true}
                value={this.state.password}
                onChange={(e) => this.setState({ password: e.target.value })}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => this.setState({ enablePasswordField: !this.state.enablePasswordField })}
                      edge="end"
                    >
                      {this.state.enablePasswordField ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {this.state.passError}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "10px 0px",
              }}
            >
              <Box
                sx={{
                  fontSize: "18px",
                  color: "#6200EE",
                  fontWeight: "bold",
                  marginTop: "5px",
                }}
              >
                <Checkbox
                  data-test-id={"btnRememberMe"}
                  onClick={() =>
                    this.setState({ checkedRememberMe: !this.state.checkedRememberMe })
                  }
                  checked={this.state.checkedRememberMe}
                />{" "}
                Remember Me
              </Box>
              <Button
                data-test-id={"btnEmailLogIn"}
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => this.doEmailLogIn()}
              >
                Login {/*UI Engine::From Sketch*/}
              </Button>
            </Box>
          </Box>
        </Box>
      </Container>
    );
  }
}
