import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  attachment: Array<{
    id: string,
    type: string,
    attributes: {
        id: number,
        account_id: number,
        attachment_type: string,
        email: string,
        name: string,
        file: {
          id: number,
          url: string
        }
    }
  }>;
  token: string;
  loading: boolean;
  openSnackBar: boolean;
  snackbarMessage: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AttachmentController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  ArcMMsgId: string = '';
  ItemsMsgId: string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.deleteitem = this.deleteitem.bind(this);
    this.mediaItems = this.mediaItems.bind(this);
    this.navigateToArchive = this.navigateToArchive.bind(this);
    this.closeSnackBar = this.closeSnackBar.bind(this);
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      attachment: [],
      token: "",
      loading: true,
      openSnackBar: false,
      snackbarMessage: ''
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);


    // Customizable Area Start
    this.setState({ loading: false });
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.ItemsMsgId != null &&
      this.ItemsMsgId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && responseJson.errors) {
        this.setState({
          attachment: [],
          openSnackBar: true,
          snackbarMessage: 'Something went wrong!'
        });
      } else {
        this.setState({
          attachment: responseJson.data
        });
      }
    }
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.ArcMMsgId != null &&
      this.ArcMMsgId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && responseJson.errors) {
        this.setState({
          openSnackBar: true,
          snackbarMessage: 'Something went wrong'
        })
      } else {
        this.mediaItems();
        this.setState({
          openSnackBar: true,
          snackbarMessage: 'Archived Successfully'
        });
      }
    }

    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {

    let token =await getStorageData("logIn_token")
    if (token) {
      this.setState({ token: token }, () => this.mediaItems());
    } else {
      this.setState({ token: "" }, () => this.mediaItems());
    }
  }
  deleteitem = (archivableId: number) => {

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token
    };
    const requestData = {
        attributes: {
            archivable_id: archivableId,
            archivable_type: "BxBlockArchive::Attachment"
        }
    };

    const httpBody = {
      data: requestData
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.ArcMMsgId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.archiveData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  mediaItems = () => {
    this.setState({
      loading: true,
    });
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.ItemsMsgId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.attachmentsList
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  navigateToArchive = () => this.props.navigation.navigate("Archive");

  closeSnackBar = () => {
    this.setState({
      openSnackBar: false,
      snackbarMessage: ''
    })
  }
  // Customizable Area End
}
