import React from "react";
// Customizable Area Start
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Snackbar from '@material-ui/core/Snackbar';

import AttachmentController, {
  Props,
} from "./AttachmentController.web";

export default class Attachment extends AttachmentController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <>
        <Typography align="center" style={{ margin: '20px' }} variant="h3" component="h2" gutterBottom>Media</Typography>
        {this.state.loading ? (
          <div
            style={{
              textAlign: 'center',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
            id="loadingTag"
          >
            <CircularProgress />
          </div>
        ) : null}
        <Button  data-testid= "btnrestor" onClick={this.navigateToArchive}>Go To Archive</Button>
        {!this.state.loading && this.state.attachment.length == 0 ? (
          <p style={{ textAlign: 'center' }}>No Attachments Found</p>
        ) : null}
        {!this.state.loading && this.state.attachment.length > 0 ? (
          <List dense={true} style={{ background: 'darkgrey', padding: '0 20px' }}>
            {this.state.attachment.map((item, index) => (
              <ListItem key={index} style={{ background: "white", margin: '20px 0px', textTransform: 'capitalize' }}>
                <ListItemText
                  primary={item.attributes.name ?? ''}
                  secondary={item.attributes.attachment_type}
                />
                <ListItemSecondaryAction>
                  <Button variant="contained" color="primary" data-testid="deleteItem" onClick={() => this.deleteitem(item.attributes.id)} size="medium">
                    Archive
                  </Button>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        ) : null}
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          open={this.state.openSnackBar}
          onClose={this.closeSnackBar}
          message={this.state.snackbarMessage}
          key={'bottomright'}
        />
      </>
    );
  }
}

// Customizable Area End

