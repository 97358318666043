//@ts-nocheck
import React, { Component } from "react";

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

import {
  StyleSheet,
  Text,
  View,
  Button,
  ScrollView,
  Platform,
  TouchableOpacity,
  AsyncStorage,
  Image
} from "react-native";
import CustomTitle from "../../../components/src/CustomTitle";
import CustomCard from "../../../components/src/CustomCard";
import { getStorageData, scaledSize } from "../../../framework/src/Utilities";
import RootView from "../../../components/src/RootView";
import { deviceBasedDynamicDimension } from "../../../mobile/Utilities";
import { Icon } from "react-native-elements";
import scale, { verticalScale } from "../../../components/src/Scale";
import { up, down } from './assets'
import { COLORS, FONTS } from "../../../framework/src/Globals";
import Loader1 from "../../../components/src/Loader1";

interface Props {
  navigation: any;
  id: string;
  FAQsApiCallID: any;
}

interface S {
  message: string;
  title: string;
  buttonText: string;
  buttonMessage: Message;
  token: string;
  Questions: any[]
  loader: boolean;
  Compare: any;
  question1: boolean,
  question2: boolean,
  question3: boolean,
  experience: any,

}

interface SS {
  id: any;
  FAQsApiCallID: any;
}

export default class InfoPageBlock extends BlockComponent<Props, S, SS> {
  [x: string]: any;
  FAQsApiCallID: string;
  constructor(props: Props) {
    super(props);

    this.state = {
      message: "An error has occured.",
      buttonText: "Ok",
      buttonMessage: new Message(""),
      title: "ERROR",
      token: "",
      Questions: [],
      question1: false,
      question2: false,
      question3: false,
      experience: "",
      Compare: [],
      loader: true
    };

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    console.log("here  ==========================>>>>>>>>>>>>>>>>>>>>>>>>>>>>");
  }

  FAQApiCall = () => {
    this.setState({ loader: true })
    const header = {
      "Content-Type": "application/json",
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.FAQsApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_contact_us/faq"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true
  };

  render() {
    const { Questions, Compare, loader } = this.state
    return (
      <RootView>
        <CustomTitle backArrow={true} password={'2'}
          onPress={() => {
            this.props.navigation.reset({
              index: 0,
              routes: [
                {
                  name: "MainNavigator",
                },
              ],
            });
          }}
          mainTitle={"FAQs"}
        />
        <View style={{ marginTop: scaledSize(19) }} />
        <Loader1 isVisible={loader} />
        <ScrollView style={styles.mainview}>
          {Questions && Questions.length > 0 ? Questions.map((item) => {
            return <View style={{ width: '90%', alignSelf: 'center' }}>
              <TouchableOpacity style={[styles.questionview, { borderBottomWidth: item.visible ? 0 : 1 }]} onPress={() => {
                Compare.map((data) => {
                  if (data.id == item.id && !data.visible) {
                    return data.visible = true
                  }
                  else if (data.id == item.id && data.visible) {
                    return data.visible = false
                  }
                  else {
                    return data.visible = false
                  }
                })
                this.setState({ Questions: Compare })
              }}>
                <Text style={styles.questiontxt}> {item?.attributes?.question}</Text>
                <Image
                  source={item.visible ? up : down}
                  style={styles.DropDownIcon}
                  resizeMode="contain"
                />
              </TouchableOpacity>
              {item.visible ? (
                <View style={[styles.answerview, { borderBottomColor: COLORS.white, borderBottomWidth: 1 }]}>
                  <Text style={styles.answer}>{item?.attributes?.description}</Text>
                </View>
              ) : null}
            </View>
          })
            : !loader ? <Text style={styles.noData}>No Queries</Text> : null}
        </ScrollView>
      </RootView >
    );
  }

  async componentDidMount() {
    let editEmailToken = await getStorageData("logIn_token");
    this.setState({ token: editEmailToken });
    this.FAQApiCall();
  }

  async receive(from: String, message: Message) {
    runEngine.debugLog("Message Recived>>>>>>>", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.FAQsApiCallID) {
        console.log("under FAQs ");

        if (responseJson != null) {

          if (!responseJson.errors) {
            responseJson.data.map((item) => {
              return item.visible = false
            })
            this.setState({
              Questions: responseJson.data, Compare: responseJson.data
            });
            this.setState({ loader: false })
          } else {
            this.setState({ loader: false })
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.setState({ loader: false })
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
  }
}


const styles = StyleSheet.create({
  noData: { textAlign: 'center', marginTop: scaledSize(60), fontSize: scaledSize(25), color: COLORS.white, fontFamily: FONTS.QuicksandBold },
  btnStyleWebContainer: { justifyContent: "flex-end", flexDirection: "row" },
  btnStyleMobileContainer: {},
  questionview: {
    flexDirection: 'row',
    justifyContent: "space-between",
    width: '100%',
    alignItems: 'center',
    borderBottomWidth: scale(1),
    borderBottomColor: 'white',
    minHeight: scale(60),
    marginBottom: scaledSize(20)
  },
  DropDownIcon: {
    marginRight: scaledSize(0),
    top:scaledSize(-10),
    width: '10%'
  },
  questiontxt: {
    fontSize: scale(18),
    width: '90%',
    //justifyContent:'flex-start',
    paddingBottom: scaledSize(5),
    //marginLeft: scaledSize(20),
    fontWeight: 'bold',
    color: 'white'
  },
  answer: {
    fontSize: scale(16),
    width: scale(350),
    minHeight: scaledSize(60),
    color: 'white'
  },
  answerview: {
    marginBottom: scaledSize(10),
    //marginTop: scaledSize(-25),
    paddingBottom: scale(20)
  }
});
