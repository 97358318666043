//Consts
export const COLORS = {
  TRANSPARENT: 'transparent',
  white: 'rgb(255,255,255)',
  black: 'rgb(0,0,0)',
  black10: 'rgb(10,10,10)',
  blue: 'rgb(0, 187, 242)',
  green: 'rgb(76,217,100)',
  red: 'red',
  yellow: 'rgba(255, 212, 40, 1)',
  darkGray: 'rgba(105,105,105,0.9)',
  lightGray: '#a7b6b9',
  skipGray: 'rgb(91,91,92)',
  borderColor: 'rgb(240, 240, 240)',
  inactiveIndicator: 'rgb(228, 233, 242)',
  inputLabel: 'rgb(143,155,179)',
  inputIcon: 'rgb(197,206,224)',
  inputValue: 'rgb(46,58,89)',
  discountRed: 'rgb(199,5,28)',
  orange: 'rgb(255,157,43)',
  infoGray: 'rgb(163,164,165)',
  ReviewBlack: 'rgb(34,43,69)',
  blueGrey: 'rgb(143,155,179)',
  darkBlueGrey: 'rgb(46,58,89)',
  lightBlueGrey: 'rgb(197, 206, 224)',
  backgroundColor: "rgba(31, 22, 70, 1)",
  primaryShadow: '#cccccc',
  primaryBlue:  "rgba(31, 22, 70, 1)",
  skyBlue: '#3BB9FF',
  pink:'#e31c94',
  lightBlue:'#54c0e8',
  modalBlack: 'rgba(0, 0, 0, 0.5)',
};

export const BORDER_RADIUS={
  radius4:4,
  radius5:5,
  radius6:6,
  radius7:7,
  radius8:8,
  radius9:9
}

export const FONTS = {
  QuicksandBold: 'Quicksand-Bold',
  QuicksandLight: 'Quicksand-Light',
  QuicksandMedium: 'Quicksand-Medium',
  QuicksandRegular: 'Quicksand-Regular',
  QuicksandSemiBold: 'Quicksand-SemiBold',
  OpenSansBold: 'OpenSans-Bold',
  OpenSansBoldItalic: 'OpenSans-BoldItalic',
  OpenSansExtraBold: 'OpenSans-ExtraBold',
  OpenSansExtraBoldItalic: 'OpenSans-ExtraBoldItalic',
  OpenSansItalic: 'OpenSans-Italic',
  OpenSansLight:'OpenSans-Light',
  OpenSansLightItalic: 'OpenSans-LightItalic',
  OpenSansMedium: 'OpenSans-Medium',
  OpenSansMediumItalic: 'OpenSans-MediumItalic',
  OpenSansRegular: 'OpenSans-Regular',
  OpenSansSemiBold: 'OpenSans-SemiBold',
  OpenSansSemiBoldItalic: 'OpenSans-SemiBoldItalic',
  CormorantBold:"Cormorant-Bold"
};