//@ts-nocheck
import { TouchableOpacity, Text, StyleSheet, View, Platform } from "react-native";
import { deviceBasedDynamicDimension } from "../../framework/src/scale";
import { COLORS, FONTS } from "../../framework/src/Globals";

import React from "react";
import { Icon } from "react-native-elements";
import { scaledSize } from "../../framework/src/Utilities";

interface myProps {
  mainTitle?: any;
  customStyles?: any;
  subTitle?: any;
  midTitle?: any;
  backArrow?: any;
  onPress?: any;
  arrowStyles?: any;
  touchableArrow?: any;
  titleStyle?: any;
  password:any;
  testID?: string;
}
export default function CustomTitle(props: myProps) {
  return (
    <>
      {props.backArrow ? (
        <View style={[styles.arrowView, props.arrowStyles,
        {marginRight:props.mainTitle==="SUBSCRIPTIONS"||"COMPARE PLANS"||"CONTACT US"?scaledSize(30):0}]}>
          <TouchableOpacity testID={props?.testID} onPress={props?.onPress}
           style={[styles.arrowStyle, props.touchableArrow,]} >
            <Icon name='arrow-left' type='font-awesome' color='#fff' />
          </TouchableOpacity>
          <View style={{flex:1,marginStart:scaledSize(25),
            marginRight:props.mainTitle==="SUBSCRIPTIONS"?scaledSize(14):0}}>
          <Text style={[props.password ? styles.mainTitleStyle2     :  styles.mainTitleStyle1  ]}>
            {props.mainTitle}
          </Text>
          </View>
          <View />
        </View>
      ) : (
        <Text style={styles.mainTitleStyle}>
          {props.mainTitle}
        </Text>
      )}
      {props.midTitle ? <Text style={styles.midTitleStyle}>
        {props.midTitle}
      </Text>
        : null}
      {props.subTitle ? <Text style={styles.subTitleStyle}>
        {props.subTitle}
      </Text> : null}
    </>
  );
}

const styles = StyleSheet.create({
  arrowView: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    textAlign: 'center',
  },
  mainTitleStyle: {
    // paddingTop: deviceBasedDynamicDimension(28, true, 1),
    color: "rgba(255, 255, 255, 1)",
    textAlignVertical: "top",
    textAlign:'center',
    fontFamily: FONTS.QuicksandSemiBold,
    fontSize: deviceBasedDynamicDimension(34, true, 1),
  },
  mainTitleStyle1: {
    paddingTop: deviceBasedDynamicDimension(28, true, 1),
    //left: deviceBasedDynamicDimension(32, true, 1),
    color: "rgba(255, 255, 255, 1)",
    alignItems:'center',
    textAlign:'center',
    textAlignVertical: "top",
    fontFamily: FONTS.QuicksandSemiBold,
    fontSize: deviceBasedDynamicDimension(29, true, 1),
  },
  mainTitleStyle2: {
    paddingTop: deviceBasedDynamicDimension(28, true, 1),
    left: deviceBasedDynamicDimension(-15, true, 1),
    color: "rgba(255, 255, 255, 1)",
    //alignItems:'center',
    textAlign:'center',
    textAlignVertical: "top",
    fontFamily: FONTS.QuicksandSemiBold,
    fontSize: deviceBasedDynamicDimension(34, true, 1),
  },

  midTitleStyle: {
    paddingTop: deviceBasedDynamicDimension(30, true, 1),
    paddingBottom: scaledSize(10),
    color: "rgba(70, 189, 236, 1)",
    textAlign: "center",
    textAlignVertical: "top",
    fontFamily: FONTS.QuicksandMedium,
    fontSize: deviceBasedDynamicDimension(24, true, 1),
  },

  subTitleStyle: {
    paddingBottom: deviceBasedDynamicDimension(20, true, 1),
    color: "rgba(255, 255, 255, 1)",
    textAlign: "center",
    textAlignVertical: "top",
    fontFamily: FONTS.OpenSansRegular,
    fontSize: deviceBasedDynamicDimension(14, true, 1),
    paddingHorizontal: deviceBasedDynamicDimension(15, true, 1)
  },
  arrowStyle: {
     zIndex:2,
    marginTop: Platform.OS == "ios" ? deviceBasedDynamicDimension(28, true, 1) : deviceBasedDynamicDimension(32, true, 1),
    left: scaledSize(25),
  }
});
