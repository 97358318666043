// Customizable Area Start
import React from "react";
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Container from '@material-ui/core/Container';
import Snackbar from '@material-ui/core/Snackbar';
import {Modal,Box} from "@material-ui/core"


// Customizable Area End

import PremiumPlanComparisonController, {
  Props
} from "./PremiumPlanComparisonController";

export default class PremiumPlanComparison extends PremiumPlanComparisonController {
  
  constructor(props: Props) {
    super(props);
    // Customizable Area Start\
    // Customizable Area End
  }

  // Customizable Area Start
  renderModalForSelectedPlan=()=>{
    return (
      <Modal 
      open={this.state.flag}
      onClose={()=>this.CloseModal()}>
        <Box style={{flex:1,justifyContent:"center",alignSelf:"center",width:"50%",margin:"20%"}}>
        <Card variant="outlined" style={{
            backgroundColor: '#f35d8b',
            border: '2px solid #614051',
            color: 'white'
          }}>
            <CardContent>
              <Typography gutterBottom>
                {this.state.selectedPlan.plan_name}
              </Typography>
              <Typography  gutterBottom>
                {`${this.state.selectedPlan.currency}${this.state.selectedPlan.amount} / ${this.state.selectedPlan.duration}`}
              </Typography>
              <Typography  gutterBottom>Features:</Typography>
              <List component="nav" aria-label="main mailbox folders">
                {this.state.selectedPlan.features?.map((feat: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined) => <>
                    <ListItem>
                      <ListItemText primary={feat} />
                    </ListItem>
                    <Divider />
                  </>
                )}
              </List>
              <CardActions style={{ justifyContent: 'center' }}>
                <Button
                  data-test-id="activatePlan"
                  variant="contained"
                  onClick={() => this.ActivatePlan(this.state.selectedPlan)}
                  size="small"
                  style={{
                    background:  'rgb(195,195,195)',
                    color:'black'
                  }}
                >
                {'Activate Plan'}
                </Button>
              </CardActions>
            </CardContent>
          </Card>
        </Box>
      </Modal>
    )
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Container>
        <Typography variant="h3" align="center" >Premium Plans</Typography>
        <div style={{
          textAlign: 'center',
          display: 'grid',
          gridTemplateColumns: '0.5fr 0.5fr',
          justifyContent: 'space-evenly',
          alignItems: 'center',
          rowGap: '30px',
          columnGap: '30px',
          padding: '30px'
        }}>
        {this.state.showLoader ? (
          <p>loading...</p>
        ) : null}
        {!this.state.showLoader && this.state.subscriptions.map((plan) => (
          <Card variant="outlined" style={{
            backgroundColor: '#f35d8b',
            border: '2px solid #614051',
            color: 'white'
          }}>
            <CardContent>
              <Typography gutterBottom>
                {plan.attributes.plan_name}
              </Typography>
              <Typography  gutterBottom>
                {`${plan.attributes.currency}${plan.attributes.amount} / ${plan.attributes.duration}`}
              </Typography>
              <Typography  gutterBottom>Features:</Typography>
              <List component="nav" aria-label="main mailbox folders">
                {plan.attributes.features?.map((feat) => <>
                    <ListItem>
                      <ListItemText primary={feat} />
                    </ListItem>
                    <Divider />
                  </>
                )}
              </List>
              <CardActions style={{ justifyContent: 'center' }}>
                <Button
                  data-test-id="selectPlan"
                  variant="contained"
                  disabled={this.state.activePlan.id == plan.attributes.id}
                  onClick={() => this.selectedPlanFn(plan.attributes)}
                  size="small"
                  style={{
                    background: this.state.activePlan.id != plan.attributes.id ? 'rgb(195,195,195)' : '#614051',
                    color: this.state.activePlan.id != plan.attributes.id ? 'black' : 'white'
                  }}
                >
                {this.state.activePlan.id == plan.attributes.id ? 'Activated' : 'Select Plan'}
                </Button>
              </CardActions>
            </CardContent>
          </Card>
        ))}
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          open={this.state.openSnackbar}
          onClose={this.closeSnackBar}
          message={this.state.snackbarMessage}
          key={'bottomright'}
        />
        </div>
        {this.state.flag && this.renderModalForSelectedPlan()}
      </Container>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};
// Customizable Area End
