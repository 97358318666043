import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";

// Customizable Area Start
import { Dimensions } from "react-native";
import { getStorageData } from "../../../framework/src/Utilities";
let screenWidth = Dimensions.get('window').width;
let screenHeight = Dimensions.get('window').height;
//Artboard Dimension
let artBoardHeightOrg = 844;
let artBoardWidthOrg = 390;

export interface SelectedPlanInterFace {
  id:	number,
  duration:string,
  amount:number,
  currency:string,
  plan_name:string,
  features:string[]|null
}
export interface ResponseDataForSubscriber{
  data:{
    id:string,
    type:string,
    attributes:{
      id:number,
      subscription_plan:{
        id:number,
        duration:string,
        amount:number,
        created_at:string,
        updated_at:string,
        is_archive:boolean,
        plan_name:string,
        currency:string
     },
      amount:number,
      expiry_date:number,

    }
    },
    errors:[]
} 
export interface ResponseJsonData {
  data:[{
    id:string,
    type:string,
    attributes:{
      amount:number,
      currency:string,
      duration:string,
      features:string[],
      id:number,
      plan_name:string
    },
}],
errors:[]

}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  route: 
  {
    params:{
      subscriptionid: string
    }
  }
  // Customizable Area End

}

interface S {
  // Customizable Area Start
  showLoader: boolean;
  token: string;
  planId: string;
  showAlert: boolean;
  subscriptions: {
    id: string,
    type: string,
    attributes: {
      id: number,
      duration: string,
      amount: number,
      currency: string,
      plan_name: string,
      features: string[],
    }
  }[];
  displayedSubscription: {
    id: string,
    type: string,
    attributes: {
      id: number,
      duration: string,
      amount: number,
      currency: string,
      plan_name: string,
      features: string[],
    },

  };
  currentPlan: [];
  selectedPlan:SelectedPlanInterFace
  activePlan:SelectedPlanInterFace
  snackbarMessage: string;
  flag:boolean;
  openSnackbar: boolean;
  // Customizable Area End
}

interface SS {
  id: number;
  // Customizable Area Start
  // Customizable Area End
}

export default class PremiumPlanComparisonController extends BlockComponent<
  Props,
  S,
  SS
>
{
  // Customizable Area Start
  getAllSubscriptionsAPIId: string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.state = {
      showLoader: true,
      planId: '',
      token: "",
      subscriptions: [],
      displayedSubscription: {} as S['displayedSubscription'],
      showAlert: false,
      currentPlan: [],
      activePlan: {
        id: 0,
        duration: "",
        amount: 0,
        currency: "",
        plan_name: "",
        features: null
      },
      snackbarMessage: "",
      openSnackbar: false,
      selectedPlan:{
        id: 0,
        duration: "",
        amount: 0,
        currency: "",
        plan_name: "",
        features: null
      },
      flag:false
    };
    screenWidth = Dimensions.get('window').width;
    screenHeight = Dimensions.get('window').height;
    // Customizable Area End
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    let tokens = await getStorageData("logIn_token");
    if (tokens) {
      this.setState({ token: tokens }, () => {
        this.getAllSubscriptionPlansDetails()
      });
    } 
    // this.setState({
    //   planId: this.props.route.params.subscriptionid
    // })
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    this.setState({ showLoader: false });

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
        
    
      if (apiRequestCallId === this.getAllSubscriptionsAPIId) {
        this.checkResponseGetAllSubscriptions(responseJson,errorReponse)
      }

    }
    // Customizable Area End
  }

  // Customizable Area Start


 
  checkResponseGetAllSubscriptions = (responseJson: ResponseJsonData,errorReponse:{errors:""}) => {
    if(errorReponse){
      alert(errorReponse.errors)
    }
    if (responseJson && !responseJson.errors) {
      this.setState({
        subscriptions: responseJson.data,
      })
      const subObj: S['displayedSubscription'] = this.state.subscriptions.find((subcption) => subcption.id == this.state.planId)!
      
      this.setState({
        displayedSubscription: subObj
      })
    }else{
      alert(responseJson.errors)
    }
  }

 

  

  getAllSubscriptionPlansDetails = () => {
    this.setState({ showLoader: true });
    const header = {
      "Content-Type": configJSON.ContentType,
      token: this.state.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllSubscriptionsAPIId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GETMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  selectedPlanFn=(plan:SelectedPlanInterFace)=>{
    this.setState({selectedPlan:plan,flag:!this.state.flag})
  }
  CloseModal=()=>{
    this.setState({flag:!this.state.flag})
  }
  ActivatePlan=(plan:SelectedPlanInterFace)=>{
    this.setState({
      activePlan:plan,
      snackbarMessage: 'Premium Plan Activated Successfully',
      openSnackbar: true,
      flag:!this.state.flag
    })
  }
  closeSnackBar = () => {
    this.setState({
      openSnackbar: false,
      snackbarMessage: ''
    })
  }


  handleSelectPlan = (plan: S['displayedSubscription']) => {
    this.setState({
      planId: plan.id,
      displayedSubscription: plan
    })
  }

  handleGoBack = () => {
    this.props.navigation.goBack()
  } 

  // Customizable Area End
}