//@ts-nocheck
import React from "react";
import { View, SafeAreaView, Platform, StyleSheet } from "react-native";
import { COLORS } from "../../framework/src/Globals";
//local imports

interface myProps {
  children: any;
  customStyles?: any
}

const RootView = (props: myProps) => {
  return Platform.OS == "android" ? (
    <SafeAreaView style={[styles.rootView, props.customStyles]}>{props.children}</SafeAreaView>
  ) : (
    <SafeAreaView style={[styles.rootView, props.customStyles]}>{props.children}</SafeAreaView>
  );
};

const styles = StyleSheet.create({
  rootView: {
    flex: 1,
     backgroundColor: COLORS.backgroundColor,
    //  backgroundColor: 'red',
  },
});

export default RootView;
