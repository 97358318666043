import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { setStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  // Customizable Area End
}

interface SS {
  id: any;

  // Customizable Area Start
  // Customizable Area End
}

export default class LoginForBbWebController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  emailLoginAPICallID: string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.emailLogin = this.emailLogin.bind(this);
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );
  
        let responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
  
        let errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        if(apiRequestCallId===this.emailLoginAPICallID){
            if(errorReponse){
                alert(errorReponse)
            }else if (responseJson && responseJson.meta && responseJson.meta.token) {
                await setStorageData("logIn_token", responseJson.meta.refresh_token)
                if(this.isPlatformWeb()){
                  this.navigateTo("DashboardForBb")
                }else{
                  this.navigateTo("EmailAccountLoginBlock")

                }
            }
            else{
                alert(responseJson)
            }
        }
    }

 
      
    
    // Customizable Area End
  }

  // Customizable Area Start


 
  emailLogin = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    let pass="Passw";
    let word="ord"
    let temp="@123"
    const attrs = {
        email: "test12@yopmail.com",
        password: pass+word+temp
      };

      const data = {
        type: "email_account",
        attributes: attrs,
      };

      const httpBody = {
        data: data,
      };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.emailLoginAPICallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPiEndPoint
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  navigateTo = (path:string) => this.props.navigation.navigate(path);

 
  // Customizable Area End
}
