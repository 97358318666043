import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Alert } from "react-native";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValueNative: string;
  txtSavedValueNative: string;
  enableFieldNative: boolean;
  // Customizable Area Start
  archivedItems: Array<{
        "id": string,
        "type": string,
        "attributes": {
            "id": number,
            "account_id": number,
            "archivable_type": string,
            "archivable_id": number,
            "email": string,
            "archived_object": {
                "id": number,
                "attachment_type": string,
                "account_id": number,
                "name": string,
                "image_url": string
            }
        }
    }>;
  token: string;
  loading: boolean;
  openSnackBar: boolean;
  snackbarMessage:string
  // Customizable Area End
}

interface SS {
  id: any;

  // Customizable Area Start
  // Customizable Area End
}

export default class ArchiveController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  ListMsgApiCallID: string = '';
  deleteMsgApiCallID: string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.ArchiveItemsFn = this.ArchiveItemsFn.bind(this);
    this.restoreArchives = this.restoreArchives.bind(this);
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      txtInputValueNative: "",
      txtSavedValueNative: "A",
      enableFieldNative: false,
      // Customizable Area Start
      archivedItems: [],
      token: "",
      loading: true,
      openSnackBar: false,
      snackbarMessage: '',
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    this.setState({ loading: false });
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.ListMsgApiCallID != null &&
      this.ListMsgApiCallID ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

    if (responseJson && responseJson.errors){
      this.setState({
        openSnackBar: true,
        snackbarMessage: 'Something went wrong!'
      });
      Alert.alert("erorr")
     } else{

       this.setState({
         archivedItems: responseJson.data
        });
      }
      
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.deleteMsgApiCallID != null &&
      this.deleteMsgApiCallID ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

    if (responseJson && responseJson.errors){
      this.setState({
        openSnackBar: true,
        snackbarMessage: 'Something went wrong!'
      });
      alert('Error')
     } else{

       this.setState({ archivedItems: [] });
       this.ArchiveItemsFn();
       this.setState({
         openSnackBar: true,
         snackbarMessage: 'Restored Successfully'
        });
        alert('Un-archive Successfully');
      }
      
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    
    let tokens =await getStorageData("logIn_token")
    if (tokens) {
      this.setState({ token: tokens }, () => this.ArchiveItemsFn());
    } 
  }

  ArchiveItemsFn = () => {
    this.setState({
      loading: true,
    });
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.ListMsgApiCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.archiveData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleGoBack = () => {
    this.props.navigation.goBack()
  }

  restoreArchives = (attachmentId: string) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteMsgApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.archiveData}/${attachmentId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  navigateTo = () => this.props.navigation.navigate("Attachment");

  closeSnackBar = () => {
    this.setState({
      openSnackBar: false,
      snackbarMessage: ''
    })
  }
  // Customizable Area End
}
