'use strict';

const baseURL = "__MARKER_FOR_BACKEND_URL_REPLACEMENT";
// const baseURL = 'https://danielsapp-108485-ruby.b108485.dev.us-east-1.aws.svc.builder.cafe';
// const baseURL = 'https://danielsapp-108485-ruby.b108485.stage.eastus.az.svc.builder.ai'
Object.defineProperty(exports, '__esModule', {
  value: true
});

exports.baseURL = baseURL;

