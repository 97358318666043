import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Typography,

} from "@material-ui/core";


// Customizable Area End

import LoginControllerForBB, {
  Props,
} from "./LoginForBbWebController";


export default class LoginForBB extends LoginControllerForBB {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <Container maxWidth="sm">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "10px 0px",
          }}
        >
          <Typography variant="h4" component="h2">
            Login
          </Typography>
         

          <Box sx={{ width: "100%", paddingTop: "20px" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "10px 0px",
              }}
            >
              
              <Button
                data-test-id={"btnEmailLogIn"}
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => this.emailLogin()}
              >
                Login {/*UI Engine::From Sketch*/}
              </Button>
            </Box>
          </Box>
        </Box>
      </Container>
    );
  }
}
