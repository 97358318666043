Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Archive";
exports.labelBodyText = "Archive Body";
exports.btnRestoreTitle = "RESTORE";
exports.btnArchiveTitle = "ARCHIVE";
exports.btnNavigatetoArchive = "Navigate To Archive Folder";
exports.noItems = "No Archived Items";
exports.goToAttachments = "Go To Attachments"
exports.archiveData = "bx_block_archive/archived_items";
exports.deleteApiMethod = "DELETE";
exports.attachmentsList = "bx_block_archive/attachments";
// Customizable Area End
